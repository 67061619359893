<template>
  <div id="plans">

    <submenu-target :target="'site-plan'">
      <p class="big">Patterson Architects have created a collection of tailored plans for Daresbury that exude contemporary style and ensure comfortable, joyous daily living. Each home plan has been carefully crafted to suit the geography of its site so that light, views and privacy can be enjoyed by each family. Generous layouts feature a range of spaces for living, relaxing and entertaining.</p>
      <p class="big">While initial plans have been drawn, there is ample opportunity for you to collaborate with Patterson to create a home that’s perfectly customised for you.</p>

      <h3 class="img-offset">Site Plan</h3>

      <div class="site-plan-grid">
        
        <div class="site-plan">
          <div class="north">NORTH</div>
          <inline-svg :src="$getImageSrc('site-plan')" @click="sitePlanClick"></inline-svg>
        </div>
        
        
        <ul class="detail">
          <li v-for="(lot,index) in lots">
            <ul>
              <li>Lot {{index+1}}</li>
              <li>Site Area: {{lot['Site area']}}</li>
              <li>Floor Area: {{lot['Floor area']}}</li>
              <li>Bedrooms: {{lot['Bedrooms']}}</li>
              <li>Parkings: {{lot['Parkings']}}</li>
            </ul>
          </li>
        </ul>
      </div>
    </submenu-target>
    
    <submenu-target :target="'lots-1-6'">
      <h3>LOTS 1–6</h3>
      <ul class="lot-menu">
        <li v-for="(lot, index) in lots" 
            @click="changeLot(index)" 
            :class="{active: activeLot === index}">
          {{index+1}}
        </li>
      </ul>

      <div class="img-wrapper is-cover front-elevation" :class="'lot-' + (activeLot + 1)">
        <image-helper 
          :src="'lot-' + (activeLot+1) + '-front-elevation'" 
          :key="activeLot">
        </image-helper>
      </div>
      
      
      <div class="lot-sticky-wrapper">

        <div class="lot-submenu submenu img-offset">
          <ul>
            <li :class="{'active' : floor === 0}" @click="floor=0">Ground</li>
            <li :class="{'active' : floor === 1}" @click="floor=1">First</li>
          </ul>
        </div>

        <div class="lot-detail">

          <div class="img-wrapper is-cover" :key="activeLot + floor">
            <image-helper v-if="floor === 0" :src="'lot-' + (activeLot+1) + '-ground'" ></image-helper>
            <image-helper v-else :src="'lot-' + (activeLot+1) + '-first'"></image-helper>
          </div>
          
          <div class="col">
            <ul class="lot-spec">
              <li v-for="(value, key) in lots[activeLot]">
                {{key}} : {{value}}
              </li>
              <li>
                <a href="/assets/pdf/daresbury-spec-sheet.pdf" target="_blank">SPECIFICATIONS <br v-if="!isXs">PDF →</a>
              </li>
            </ul>

            <ol class="amenities">
              <li class="key">KEY</li>
              <li v-for="amenity in amenities">{{amenity}}</li>
            </ol> 

            <div class="lot-reference">
              <div class="north">NORTH</div>
              <inline-svg :src="$getImageSrc('site-plan')" :class="'lot-' + (activeLot + 1)" >
            </div>
          </div>
        </div>

      </div>
      
    </submenu-target>


    <submenu-target :target="'materials'">
      <h3>Materials</h3>
      <p>Daresbury homes will be constructed from the highest material standards, inside and out. Durability, sustainability, health and comfort are foundational and non-negotiable. Within these standards, there is room to customise  your own signature palette of textures and colours that you’ll love coming home to every day.</p>
      <p class="p-small">
        <a href="/assets/pdf/daresbury-spec-sheet.pdf" target="_blank">DOWNLOAD SPECIFICATIONS PDF →</a>
      </p>

      <div class="materials-submenu submenu img-offset">
        <ul>
          <li :class="{'active' : palette === 'warm'}" @click="palette = 'warm'">WARM PALETTE</li>
          <li :class="{'active' : palette === 'cool'}" @click="palette = 'cool'">COOL PALETTE</li>
        </ul>
      </div>

      <div class="materials-grid img-offset" :key="palette">
        <ul v-for="(materials, index) in materials[palette]">
          <li class="img-wrapper is-cover one-one">
            <image-helper :src="palette + '-' + (index + 1)"></image-helper>
          </li>
          <li v-for="metric in materials">{{metric}}</li>
        </ul>
      </div>
    </submenu-target>
  </div>
</template>

<script>
  import SubmenuTarget from "~/components/SubmenuTarget.vue";
  var VueScrollTo = require('vue-scrollto');

  export default {
    components: { SubmenuTarget },
    data() {
      return {
        floor: 0,
        activeLot: 0,
        palette: 'warm',
        lots: [
          {
            'Site area': '620m²',
            'Floor area': '385m²',
            'Covered ratio': '20m²',
            'Bedrooms': '4',
            'Bathrooms': '4',
            'Parkings': '2+3',
          },
          {
            'Site area': '1081m²',
            'Floor area': '480m²',
            'Covered ratio': '41m²',
            'Bedrooms': '4',
            'Bathrooms': '4',
            'Parkings': '3+3',
          },
          {
            'Site Area': '1399m²',
            'Floor Area': '565m²',
            'Covered ratio': '44m²',
            'Bedrooms': '4',
            'Bathrooms': '4',
            'Parkings': '3+3',
          },
          {
            'Site Area': '797m²',
            'Floor Area': '481m²',
            'Covered ratio': '45m²',
            'Bedrooms': '5',
            'Bathrooms': '4',
            'Parkings': '3+2',
          },
          {
            'Site area': '885m²',
            'Floor area': '477m²',
            'Covered ratio': '45m²',
            'Bedrooms': '4',
            'Bathrooms': '4',
            'Parkings': '3+2',
          },
          {
            'Site area': '824m²',
            'Floor area': '398m²',
            'Covered ratio': '41m²',
            'Bedrooms': '4',
            'Bathrooms': '4',
            'Parkings': '2+2',
          }
        ],
        amenities: [
          'Living',
          'Dining',
          'Gas Fire',
          'Media/Family',
          'Study',
          'Bedroom',
          'Robe/Storage',
          'Lobby',
          'Stair',
          'Lift',
          'Garage',
          'Kitchen',
          'Pantry',
          'Bathroom',
          'Laundry',
          'Covered deck',
          'Pool'
        ],
        materials: {
          warm: [
            {
              type: 'Floor',
              location: 'Ground floor',
              material: 'Engineered Timber',
              color: 'Natural Oak'
            },{
              type: 'Floor',
              location: 'Second floor',
              material: 'Carpet',
              color: 'Light Beige'
            },{
              type: 'Cabinetry',
              location: 'Kitchen & Bedroom',
              material: 'Veneer',
              color: 'White Oak'
            },{
              type: 'Cabinetry',
              location: 'Bedroom',
              material: 'Veneer',
              color: 'Spotted Gum'
            },{
              type: 'Benchtop & Splashback',
              location: 'Kitchen',
              material: 'Natural Stone',
              color: 'Light'
            },{
              type: 'Fittings',
              location: 'General',
              material: 'Brushed SS',
              color: 'Nickel'
            },{
              type: 'Internal Tile',
              location: 'Bathroom Floor',
              material: 'Porcelain Tile',
              color: 'Off White Matt'
            },{
              type: 'Internal Tile',
              location: 'Bathroom Wall',
              material: 'Porcelain Tile',
              color: 'Off White Matt'
            }
          ],
          cool: [
            {
              type: 'Floor',
              location: 'Ground floor',
              material: 'Engineered French Oak',
              color: 'Rubio Monocoat Oil Finish Rustic'
            },{
              type: 'Floor',
              location: 'Second floor',
              material: '100% NZ Wool Carpet',
              color: 'Transpire'
            },{
              type: 'Cabinetry',
              location: 'Kitchen & Bedroom',
              material: 'Veneer',
              color: 'Walnut'
            },{
              type: 'Cabinetry',
              location: 'Bedroom',
              material: 'Veneer',
              color: 'Walnut dark'
            },{
              type: 'Benchtop & Splashback',
              location: 'Kitchen',
              material: 'Natural Stone',
              color: 'Grey'
            },{
              type: 'Fittings',
              location: 'General',
              material: 'Brushed SS',
              color: 'Gunmetal'
            },{
              type: 'Internal Tile',
              location: 'Bathroom Floor',
              material: 'Porcelain Tile',
              color: 'Grey'
            },{
              type: 'Internal Tile',
              location: 'Bathroom Wall',
              material: 'Porcelain Tile',
              color: 'Grey'
            }
          ]
        }
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs' || this.$store.state.breakpoint === 'sm'
      },
    },
    methods: {
      changeLot(index) {
        if(this.activeLot !== index) { 
          this.activeLot = index
          this.floor = 0
          this.$router.push({hash: 'lot-' + (this.activeLot+1)})
        }
      },
      sitePlanClick(event) { // not the prettiest but it works
        let self = event.srcElement
        let parent = self.parentNode
        let grandParent = parent.parentNode
        let greatGrandParent = grandParent.parentNode
        let targets = ['lot-1','lot-2','lot-3','lot-4','lot-5','lot-6']
        let result

        if(targets.includes(parent.id)) {
          result = parent.id
        }
        if(targets.includes(grandParent.id)) {
          result = grandParent.id
        }
        if(targets.includes(greatGrandParent.id)) {
          result = greatGrandParent.id
        }

        if(result) {
          this.changeLot(parseInt(result.slice(-1))-1)
          this.scrollToLots();
        }
      },
      scrollToLots() {
        let offset = 45 // see submenuTarget.scss
        if(this.breakpoint === 'xs') { offset = 25 }
        if(this.breakpoint === 'sm') { offset = 35 }

        VueScrollTo.scrollTo('#lots-1-6', {
          offset: parseInt('-' + (this.$store.state.headerHeight - offset))
        })
      }
    },
    mounted() {
      let hash = this.$route.hash
      if(hash) {
        let lotNum = parseInt(hash.substring(hash.length - 1))-1;
        this.activeLot = lotNum
        this.scrollToLots();
      }
    }
  }
</script>