
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import VueScrollTo from 'vue-scrollto'
import VueGtag from 'vue-gtag'
import Meta from 'vue-meta'
import Store from './store.js'
import Router from './router.js'
import Helpers from './helpers.js'
import {InlineSvgPlugin} from 'vue-inline-svg';
import VueObserveVisibility from 'vue-observe-visibility'
import VScrollLock from 'v-scroll-lock'
import ImageHelper from './components/ImageHelper.vue'
import App from './components/App.vue'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueScrollTo)
Vue.use(Helpers)
Vue.use(Meta)
Vue.use(InlineSvgPlugin);
Vue.use(VueObserveVisibility)
Vue.use(VScrollLock)

Vue.component('image-helper', ImageHelper)

const router = new VueRouter(Router)
const store = new Vuex.Store(Store);

Vue.use(VueGtag, {
  config: { id: "G-X3G9W26WMK" }
}, router);

new Vue({
	router,
	store,
	render: createElement => createElement(App)
}).$mount('#app');