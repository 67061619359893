<template>
  <div id="enquiries">
    <div class="faux-bg"></div>
    <div class="grid">
      <h1>ENQUIRIES</h1>
      <p>We look forward to discussing this exclusive opportunity with you. Please direct your enquiries to —</p>
      <contact-details></contact-details>
    </div>
  </div>
</template>

<script>
  import ContactDetails from "~/components/ContactDetails.vue";

  export default {
    components: { ContactDetails },
    data() {
      return {
        
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>