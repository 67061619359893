<template>
  <main :style="{marginTop: headerHeight}">
    <app-menu v-if="!errorPage"></app-menu>
    <div class="content">
      <router-view :key="route"></router-view>
    </div>
    <app-footer v-if="!errorPage && !enquiryPage"></app-footer>
  </main>
</template>

<script>
  import AppMenu from '~/components/AppMenu.vue'
  import AppFooter from '~/components/AppFooter.vue'
  var VueScrollTo = require('vue-scrollto');

  export default {
    components: { AppMenu, AppFooter },
    data() {
      return {
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      route() {
        return this.$route.name
      },
      errorPage(){
        return this.route === 'error'
      },
      enquiryPage() {
        return this.route === 'enquiries'
      },
      headerHeight() {
        return this.$store.state.headerHeight + 'px';
      }
    },
    watch: {
      $route() {
        if(this.$route.hash === '') {
          this.$nextTick(()=>{
            VueScrollTo.scrollTo('html')
          });
        } 
      }
    }
  }
</script>