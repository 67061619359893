<template>
  <header v-scroll-lock="overlay" :class="'is-' + route" ref="header">
    <div class="primary" :class="{'active' : overlay}">
      <router-link :to="{name: 'home'}" @click.native="closeOverlay()" class="logo">DARESBURY</router-link>
      <div class="menu-btn" @click="toggleOverlay">{{menuBtn}}</div>
    </div>
    
    <div class="submenu container" v-if="showSubmenu">
      <h1>{{$toTitle(route)}}</h1>
      <ul>
        <li v-for="(child, index) in menuData[route].children" 
            :class="{'active':child === activeChild}"
            @click="submenuClick(child, index)">
          {{formatChild(child)}}
        </li>
      </ul>
    </div>

    <nav :class="{'active' : overlay}">

      <ul class="container">
        <li v-for="parent in menuData">
          <router-link :to="{name: parent.name}" @click.native="closeOverlay()">{{$toTitle(parent.name)}}</router-link>
        </li>
      </ul>
    </nav>

    
  </header>
</template>


<script>
  import { EventBus } from '/event-bus.js';
  var VueScrollTo = require('vue-scrollto');

  export default {
    data(){
      return {
        overlay: false,
        activeChild: false,
        menuData: {
          design: {
            name: 'design',
            children: ['architecture','landscape']
          },
          location: {
            name: 'location',
            children: ['the-location','the-neighbourhood','inspiration']
          },
          plans: {
            name: 'plans',
            children: ['site-plan','lots-1-6','materials']
          },
          team: {
            name: 'team',
            children: ['patterson-architecture','rmm-landscape-architects','milne-construction']
          },
          enquiries: {
            name: 'enquiries'
          }
        }
      }
    },
    computed: {
      route() {
        return this.$route.name
      },
      showSubmenu() {
        return this.route !== 'home' && this.menuData[this.route].children
      },
      menuBtn() {
        return (this.overlay) ? 'Close' : 'Menu'
      },
      breakpoint() {
        return this.$store.state.breakpoint
      }
    },
    methods: {
      toggleOverlay() {
        this.overlay = !this.overlay
      },
      closeOverlay() {
        this.overlay = false
      },
      submenuClick(child, index) {
        let offset = 45 // see submenuTarget.scss
        if(this.breakpoint === 'xs') { offset = 25 }
        if(this.breakpoint === 'sm') { offset = 35 }

        VueScrollTo.scrollTo('#' + child, {
          offset: parseInt('-' + (this.$store.state.headerHeight - offset))
        })
      },
      formatChild(child) {
        return (child === 'lots-1-6') ? 'Lots 1-6' : this.$toTitle(child);
      },
      setHeaderHeight() {
        this.$nextTick(()=>{
          this.$store.commit('setHeaderHeight',this.$refs.header.scrollHeight)
        });
      }
    },
    watch: {
      $route() {
        this.closeOverlay()
      },
      breakpoint() {
        this.closeOverlay()
      }
    },
    mounted() {
      this.setHeaderHeight()
      EventBus.$on('updateSubmenu', (emit) => {
        this.activeChild = emit
      });

      window.addEventListener('keydown', (e) => {
        if (e.key == 'Escape') {
          this.closeOverlay()
        }
      });
    },
    watch: {
      route() {
        this.setHeaderHeight()
      },
      breakpoint() {
        this.setHeaderHeight()
      }
    }
  }
</script>