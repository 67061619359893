<template>
  <div id="design">
    <submenu-target :target="'architecture'">
      <h3>ARCHITECTURE</h3>
      <p>Designed by leading architects Pattersons, Daresbury is a suite of spacious contemporary homes which reflect the rich heritage of Fendalton.</p>
      <p>Care has been taken to create legacy buildings  that respect the craft of the past while epitomising modern design. Pitched rooves create a sense of place and style, while quality workmanship and thoughtful detailing ensure each home is imbued with soul and atmosphere. Open spaces, intuitive flow, high stud ceilings and natural light invite life in; positioning and aspect transform each home and section into its own private oasis.</p>
      <p>The vision is clear – to work with owners to create a wonderful community of iconic homes, while enabling each dwelling to be customised to suit the family, life and style within.</p>
      <p><a class="p-small" href="/assets/pdf/daresbury-spec-sheet.pdf" target="_blank">DOWNLOAD PLAN PDF →</a></p>

      <carousel-helper class="img-offset" :options="carouselOptions" :custom-nav="true">
        <swiper-slide v-for="(slide, index) in architectureImages" class="slide" :key="index">
          <div class="img-wrapper is-cover three-two">
            <image-helper :src="slide"></image-helper>
          </div>
        </swiper-slide>
      </carousel-helper>
      <p class="v-small">Artist's impressions only</p>
    </submenu-target>

    <submenu-target :target="'landscape'">
      <h3>LANDSCAPE</h3>
      <p>Inspired by Daresbury’s origin as a garden estate, Christchurch landscape architects Rough Milne Mitchell have designed a calm, leafy enclave that connects people with the outdoors.</p>
      <p>Bounded by the Waimairi stream to the northsouth, a full length totara hedge to the east and organised around a central cobblestone laneway, the suite of six properties maintains the feel of a private country estate. A number of heritage trees grace the site, and significant riparian restoration brings new life to the scene.</p>
      <p>Daresbury has been designed as a warm community and has shared community spaces including a riverside pocket park, recreation and laneways, to bring families together. Within each section, privacy is ensured. Individual properties include secure fencing, ample car-parking for residents and guests, provision for tiled lap pools, outdoor fireplaces and entertaining areas, lawns, hedging and vegetable potagers.</p>

      <carousel-helper v-if="isXs" class="img-offset" :options="carouselOptions" :custom-nav="true">
        <swiper-slide v-for="(classes, index) in landscapeImageClasses" class="slide" :key="index">
          <div class="img-wrapper is-cover three-two">
            <image-helper :src="'landscape-' + (index+1)"></image-helper>
          </div>
        </swiper-slide>
      </carousel-helper>

      <div v-else class="grid img-offset col-3 gap-10">
        <div v-for="(classes, index) in landscapeImageClasses" class="img-wrapper is-cover" :class="classes">
          <image-helper :src="'landscape-' + (index+1)"></image-helper>
        </div>
      </div>
    </submenu-target>
  </div>
</template>

<script>
  import SubmenuTarget from "~/components/SubmenuTarget.vue";
  import CarouselHelper from "~/components/CarouselHelper.vue";
  import { SwiperSlide } from 'vue-awesome-swiper'

  export default {
    components: { SubmenuTarget, CarouselHelper, SwiperSlide },
    data() {
      return {
        architectureImages: ['daresbury-1-1','daresbury-1-2','daresbury-6','daresbury-4'],
        landscapeImageClasses: ['three-four','','','four-three col span-2-col span-2-row','',''],
        carouselOptions: {
          centeredSlides: true,
          spaceBetween: 10,
          slidesPerView: 'auto',
        },
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      }
    },
    methods: {
    }
  }
</script>