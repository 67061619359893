<template>
  <div id="location">
    <submenu-target :target="'the-location'">
      <h3>The LOCATION</h3>
      <p>Daresbury is a secure, gated community located in the Christchurch suburb of Fendalton. The city centre is a ten minute walk through beautiful Hagley Park; the airport a ten minute drive in the opposite direction. With mature trees overhead and the best of Ōtautahi all around, Daresbury brings the magic of city and country to your doorstep.</p>

      <carousel-helper v-if="isXs" class="img-offset" :options="carouselOptions" :custom-nav="true">
        <swiper-slide v-for="(slide, index) in locationImages" class="slide" :key="index">
          <div class="img-wrapper is-cover three-two">
            <image-helper :src="'location-' + (index+1)"></image-helper>
          </div>
        </swiper-slide>
      </carousel-helper>

      <div v-else class="img-offset grid col-2 gap-10">
        <div v-for="(image, index) in locationImages" class="img-wrapper is-cover three-two">
          <image-helper :src="'location-' + (index+1)"></image-helper>
        </div>
      </div>
    </submenu-target>

    <submenu-target :target="'the-neighbourhood'">
      <h3>THE NEIGHBOURHOOD</h3>
      <p>Fendalton is one of Christchurch’s original residential districts, where hardy pioneers first tilled the soil and set down roots. Today, this sought-after neighbourhood is home to a mix of historic and contemporary architecture, established parks and gardens, wide avenues, winding streams and mature trees. Cafés, shops, supermarkets, schools and the University of Canterbury are all within easy walking distance, and arterial roads transport you where you need to go.</p>
      <div class="img-offset img-wrapper is-cover three-two map">
        <image-helper :src="'daresbury-map'"></image-helper>
      </div>
      <div class="nearby-wrapper">
        <h3>NEARBY</h3>
        <div class="grid">
          <ul v-for="(category, key) in nearby">
            <li>{{key}}</li>
            <li v-for="item in category">{{item}}</li>
          </ul>
        </div>
      </div>
      
    </submenu-target>
   
    <submenu-target :target="'inspiration'">
      <h3>INSPIRATION</h3>
      <p>On the west side of Waimairi Stream, facing the development, sits the heritage-listed Daresbury House.</p>
      <p>Built in 1897-1901, Daresbury House is considered one of the finest grand houses in Ōtautahi. The three-storey, fifty-room house was designed by Samuel Hurst Seager and built for wine merchant George Humphreys. Over the years Daresbury has been home to governors general and welcomed King George VI among its guests.</p>
      <p>Daresbury originally featured a rookery of 100 bluegums, a rose garden, 17 gas fires, a billiards hall and Christchurch’s first private pool. Today, the ornate Tudor-style timber work, leadlight windows and extensive gables and balconies keep the fairy book feel of the homestead very much alive.</p>
      <p>This historic homestead acts as both namesake and inspiration. Its classic forms are echoed in the pitched rooves and valleys of the six new homes. Connected by a picturesque feature bridge, its presence imbues the entire site with a sense of place not commonly found in new developments.</p>
      <p>While Daresbury House is currently unoccupied due to earthquake damage, the vision is to return this landmark homestead to its former state of grace.</p>
      <div class="img-offset">
        <image-helper :src="'daresbury-illustration'"></image-helper>
      </div>
    </submenu-target>
  </div>
</template>

<script>
  import SubmenuTarget from "~/components/SubmenuTarget.vue";
  import CarouselHelper from "~/components/CarouselHelper.vue";
  import { SwiperSlide } from 'vue-awesome-swiper'

  export default {
    components: { SubmenuTarget, CarouselHelper, SwiperSlide },
    data() {
      return {
        locationImages: 6,
        carouselOptions: {
          centeredSlides: true,
          spaceBetween: 10,
          slidesPerView: 'auto',
        },
        nearby: {
          education: [
            'Medbury School 1.7km',
            'Cathedral Grammar 2.2km',
            'Christchurch Girls’ High 850m',
            'Christchurch Boys’ High 950m',
            'Rangi Ruru Girls’ School 1.3km',
            'St Margaret’s College 1.9km',
            'Christ’s College 2.2km',
            'University of Canterbury 1.7km'
          ],
          amenities: [
            'Mona Vale Gardens 80m',
            'Hagley Park 800m',
            'Botanic Gardens 1.5km',
            'Riccarton Mall 1.5km',
            'Merivale Mall 1.5km',
            'Cashel Mall 2.5km',
            'Christchurch Hospital 2.5km',
            'Margaret Mahy Playground 3km',
            'Tūranga Central Library 3km',
            'Christchurch Art Gallery Te Puna o Waiwhetū 2.5km'
          ]
        }
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      }
    },
    methods: {
    }
  }
</script>