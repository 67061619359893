<template>
  <div class="contact-details">
    <ul>
      <li>James Milne</li>
    </ul>
    <ul>
      <li><a href="tel:+6421423423">021 423 423</a></li>
    </ul>
    <ul>
      <li><a href="mailto:info@milneconstruction.co.nz">info@milneconstruction.co.nz</a></li>
    </ul>
  </div>
  
</template>


<script>

  export default {
    components: { },
    data(){
      return {
      }
    },
  }
</script>