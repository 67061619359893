<template>
  <div id="team">

    <submenu-target :target="'patterson-architecture'" class="team-grid">
      <div class="col">
        <h3>PATTERSON ARCHITECTURE</h3>
        <p>Patterson is a leading New Zealand architectural studio known for designing residential, commercial and civic projects that create a sense of belonging and beauty.</p>
        <p>Pattersons look at the entirety of a project to create a seamless experience from outside to inside.Contemporary forms, rooted in strong consideration for building usage, are underpinned by sustainable building principles and finished with elegant and robust detailing.</p>
        <p>Patterson’s work includes public icons such as the Christchurch Botanic Gardens Visitor Centre and Napier’s Len Lye Centre, as well as numerous award-winning homes throughout Christchurch and New Zealand.</p>
      </div>

      <ul class="col">
        <li class="img-wrapper is-cover one-one">
          <image-helper :src="'andrew-patterson'"></image-helper>
        </li>
        <li>Andrew Patterson</li>
        <li><a href="https://pattersons.com" target="_blank">pattersons.com</a></li>
      </ul>
    </submenu-target>

    <submenu-target :target="'rmm-landscape-architects'" class="team-grid">
      <div class="col">
        <h3>RMM Landscape Architects</h3>
        <p>Rough Milne Mitchell is a Christchurch-based landscape architecture practice with offices in Auckland, Nelson, Wanaka and Dunedin.</p>
        <p>Over their 25 year history, RMM have developed a reputation for innovation and excellence in all aspects of landscape architecture, including the protection, preservation, and enhancement of natural landscapes, both private and public.</p>
        <p>Their approach is to prioritise the integrity of the environment, balancing human needs with natural processes. Highly collaborative, the team prides itself on working together with each other and with clients to create innovative, sustainable designs that connect people with the outdoors.</p>
      </div>

      <ul class="col">
        <li class="img-wrapper is-cover one-one">
          <image-helper :src="'tony-milne'"></image-helper>
        </li>
        <li>Tony Milne</li>
        <li><a href="https://rmmla.co.nz" target="_blank">rmmla.co.nz</a></li>
      </ul>
    </submenu-target>

    <submenu-target :target="'milne-construction'" class="team-grid">
      <div class="col">
        <h3>Milne Construction</h3>
        <p>Daresbury is the brainchild of James Milne, director of Milne Construction. James and his Christchurch-based team bring over 20 years’ experience in the construction of quality architectural homes and light commercial buildings.</p>
        <p>James has always had a passion for history. When the opportunity arose to purchase Daresbury House and adjacent land, he and his wife Rosa recognised the potential for a one-of-a-kind development in a special area of Christchurch.</p>
        <p>Their vision is to open up this picturesque estate to a small group of homeowners, creating a family of homes and community of residents. Ultimately, they hope to refurbish Daresbury House itself, keeping history alive and making this breathtaking building a home once again.</p>
      </div>

      <ul class="col"> 
        <li class="img-wrapper is-cover one-one">
          <image-helper :src="'james-milne'"></image-helper>
        </li>
        <li>James Milne</li>
      </ul>
    </submenu-target>

  </div>
</template>

<script>
  import SubmenuTarget from "~/components/SubmenuTarget.vue";

  export default {
    components: { SubmenuTarget },
    data() {
      return {
        
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>