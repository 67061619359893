<template>
  <default v-if="loaded">
    <router-view></router-view>
  </default>
</template>


<script>
  import Default from '~/components/layouts/Default.vue'

  export default {
    components: { Default },
    data(){
      return {
        breakpoint: '',
        title: 'DARESBURY',
      }
    },
    computed: {
      loaded() {
        return this.$store.state.loaded
      },
      computedTitle() {
         return `${this.title} - ${this.$route.meta.title}`
      }
    },
  	methods: {
  		setup() {
  			console.log('%cDesign : https://bathgatedesign.co.nz', 'color: #000000; font-weight: bold;');
        console.log('%cDevelopment: https://kichin.co', 'color: #000000; font-weight: bold;');
        console.log('–');
  			this.breakpointCheck();
        this.$nextTick(()=>{
          this.cssVars();
        });
  		},
  		resize(e) {
  			this.breakpointCheck();
  			this.cssVars();
  		},
      breakpointCheck(resize) {
        let w = window.innerWidth;
        let breakpoint = '';

        // check these values against /assets/scss/_variables
        if (w <= 576) {
          breakpoint = 'xs';
        } else if (w > 576 && w <= 767) {
          breakpoint = 'sm';
        } else if (w >= 768 && w <= 991) {
          breakpoint = 'md';
        } else if (w >= 992 && w <= 1199){
          breakpoint = 'lg';
        } else {
          breakpoint = 'xl';
        }

        if (breakpoint !== this.breakpoint) {
          this.breakpoint = breakpoint
          this.$store.commit('setBreakpoint', breakpoint);
          console.log('Breakpoint : ' + breakpoint)
        }
      },
      cssVars() {
        // Handle VH units : get the viewport height and multiply it by 1% to get a value for a vh unit
        let vh = document.documentElement.clientHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px'); // calc(var(--vh, 1vh) * 100);
        let vw = document.documentElement.clientWidth * 0.01;
        document.documentElement.style.setProperty('--vw', vw + 'px'); // calc(var(--vw, 1vw) * 100);
      }
    },
    metaInfo() {
      return {
        title: this.computedTitle
      }
    },
    created() {
      this.setup();
      window.addEventListener("resize", this.resize);
      this.$store.commit('setLoaded', true);
    }
  }
</script>