
export default {
  state: {
    loaded: false,
    breakpoint: '',
    headerHeight: '0px'
  },
  mutations: {
    setLoaded (state, payload){
      state.loaded = payload
    },
    setBreakpoint (state, payload){
      state.breakpoint = payload
    },
    setHeaderHeight (state, payload){
      state.headerHeight = payload
    },
  },
  actions: {
  }
}