<template>
  <div :id="target" 
       class="submenu-target fluid-container" 
       v-observe-visibility="{ callback: (isVisible, entry) => visibilityChanged(isVisible, entry), intersection: { rootMargin: '-50%' } }">
    <slot>{{$toTitle(target)}}</slot>
  </div>
</template>

<script>
  import { EventBus } from '/event-bus.js';

  export default {
    components : { },
    props: {
      target: {
        type: String,
        required: true
      }
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
      visibilityChanged (isVisible, entry) {
        if(isVisible) {
          EventBus.$emit('updateSubmenu', this.$toKebab(entry.target.id));
        }
      }
    }
  }
</script>