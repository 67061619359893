<template>
  <footer>
  	<div class="fluid-container">
      <div class="grid gap-30 sm-no-gap sm-col-2">
        <ul>
          <li>Enquiries:</li>
          <li>
            <contact-details></contact-details>
          </li>
        </ul>
      </div>
  	</div>
  </footer>
</template>


<script>
  import ContactDetails from "~/components/ContactDetails.vue";

  export default {
    components: { ContactDetails },
    data(){
      return {
      }
    },
  }
</script>