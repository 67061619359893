<template>
  <div id="error" class="container img-offset">
    <inline-svg :src="$getImageSrc('daresbury-icon')"></inline-svg>
    <h2>Sorry, We can't seem to find the page you're looking for.</h2>
    <ul>
      <li>Here are some helpful links instead:</li>
      <li><router-link :to="{name: 'home'}">Home →</router-link></li>
      <li><router-link :to="{name: 'location'}">Location →</router-link></li>
      <li><router-link :to="{name: 'plans'}">Plans →</router-link></li>
      <li><router-link :to="{name: 'team'}">Team →</router-link></li>
      <li><router-link :to="{name: 'enquiries'}">Enquiries →</router-link></li>
    </ul>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    computed: {
    
    }
  }
</script>