
import Home from './components/pages/Home'
import Design from './components/pages/Design'
import Location from './components/pages/Location'
import Plans from './components/pages/Plans'
import Team from './components/pages/Team'
import Enquiries from './components/pages/Enquiries'
import ErrorPage from './components/pages/Error'


let routes = [
    {   
        path: '/', 
        name: 'home',
        component: Home,
        meta: { title: 'Home' }
    },
    {   
        path: '/design', 
        name: 'design',
        component: Design,
        meta: { title: 'Design' }
    },
    {   
        path: '/location', 
        name: 'location',
        component: Location,
        meta: { title: 'Location' }
    },
    {   
        path: '/plans', 
        name: 'plans',
        component: Plans,
        meta: { title: 'Plans' }
    },
    {   
        path: '/team', 
        name: 'team',
        component: Team,
        meta: { title: 'Team' }
    },
    {   
        path: '/enquiries', 
        name: 'enquiries',
        component: Enquiries,
        meta: { title: 'Enquiries' }
    },
    { 
        path: "*", // ensure Error is last item
        name: 'error',
        component: ErrorPage,
        meta: { 
            title: 'Error!',
            hidden: true 
        },
    }
]

export default {
    mode: 'history',
    routes: routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    // scrollBehavior (to, from, savedPosition) {
    //     return { x: 0, y: 0 }
    // }
}