<template>
  <div id="home">

    <inline-svg :src="$getImageSrc('daresbury-icon')"></inline-svg>
    <h1>A FAMILY OF HOMES IN FENDALTON</h1>
    <div class="img-wrapper outset is-cover three-two">
      <image-helper :src="'daresbury-2'"></image-helper>
    </div>
    <p class="v-small">Artist's impression only</p>
    
    <div class="intro-wrapper">
      <h2>Welcome to Daresbury</h2>
      <h2>6 Architectural Residences <br/>by Milne Construction & <br/>Patterson Architects</h2>
    </div>
    
    <p>Daresbury is an integrated family of homes located on the former grounds of Fendalton’s historic Daresbury house. Here, a confluence of river, trees and sun create a place of natural tranquillity; a private country estate within an established urban setting.</p>
    <p>The development consists of six bespoke homes, each on its own generous section. While each dwelling is unique, they work together to form a larger whole, all sharing a common aesthetic that deftly blends the classic with the contemporary.</p>
    <p>Daresbury represents a rare opportunity for owners to create their own, personalised forever home within a safe, secure and beautiful environment.</p>
    <p>Past and present, city and country, expression and belonging – all come together in perfect balance, at Daresbury.</p>

    <div class="img-w-bg">
      <div class="faux-bg"></div>
      <div class="img-wrapper is-cover three-two">
        <image-helper :src="'daresbury-3'"></image-helper>
      </div>
      <p class="v-small">Artist's impressions only</p>
    </div>

    <accordion-item v-for="(item, index) in accordion.items" :key="index" :groupUid="accordion.uid" :singleMode="true">
      <template v-slot:title>
        {{item.title}}
      </template>
      <template v-slot:content>
        <ul :class="{'is-wide':item.image.wide}">
          <li class="p-small">{{item.content}}</li>
          <li><router-link :to="{name : $toKebab(item.title)}">Read More →</router-link></li>
          <li v-if="item.title !== 'Team'" class="img-wrapper">
            <image-helper :src="item.image.src"></image-helper>
            <p v-if="item.image.render" class="v-small left">Artist's impression only</p>
          </li>
          <li v-else>
            <ul class="team grid col-3 gap-10">
              <li>
                <ul class="col grid">
                  <li class="img-wrapper is-cover one-one">
                    <image-helper :src="'andrew-patterson'"></image-helper>
                  </li>
                  <li>PATTERSON ARCHITECTURE</li>
                </ul>
              </li>
              <li>
                <ul class="col grid">
                  <li class="img-wrapper is-cover one-one">
                    <image-helper :src="'tony-milne'"></image-helper>
                  </li>
                  <li>RMM LANDSCAPE ARCHITECTS</li>
                </ul>
              </li>
              <li>
                <ul class="col grid">
                  <li class="img-wrapper is-cover one-one">
                    <image-helper :src="'james-milne'"></image-helper>
                  </li>
                  <li>MILNE CONSTRUCTION</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </accordion-item>

  </div>
</template>

<script>
  import AccordionItem from "~/components/AccordionItem.vue";

  export default {
    components : { AccordionItem },
    data() {
      return {
        accordion: {
          uid: Date.now() + Math.random(),
          status: false,
          items: [
            {
              title: 'Design',
              content: 'Designed by leading architects Pattersons, Daresbury is a suite of contemporary homes which reflect the rich heritage of the area.',
              image: {
                src: 'daresbury-5',
                wide: false,
                render: true
              }
            },
            {
              title: 'Location',
              content: 'Daresbury is a secure, gated community located in the Christchurch suburb of Fendalton.',
              image: {
                src: 'daresbury-illustration',
                wide: true
              }
            },
            {
              title: 'Plans',
              content: 'Patterson Architects have created a collection of tailored plans for Daresbury that exude contemporary style and ensure comfortable, joyous daily living.',
              image: {
                src: 'hero-elevation',
                wide: true
              }
            },
            {
              title: 'Team',
              content: 'Content',
              content: 'A collaboration between New Zealand’s leading architecture, landscaping and building teams; Daresbury has been meticulously planned with sustainability, innovation and excellence at its heart.',
              image: {
                src: false,
                wide: false
              }
            },
          ]
        },
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>